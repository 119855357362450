<template>
  <div>
    <b-row>
      <b-col>
        <contract-template />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <project-select-card />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <p-date />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <NotesCard />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <contrat-contents />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ContractTemplate from '@/views/Admin/Contracts/Elements/TemplateCard.vue'
import ContratContents from '@/views/Admin/Contracts/Elements/ContratContent.vue'
import NotesCard from '@/views/Admin/Contracts/Elements/Notes.vue'
import ProjectSelectCard from '@/views/Admin/Contracts/Elements/projectCard.vue'
import PDate from '@/views/Admin/Contracts/Elements/pdateCard.vue'

export default {
  name: 'ContratForm',
  components: {
    PDate,
    ProjectSelectCard,
    NotesCard,
    ContratContents,
    ContractTemplate,
    BRow,
    BCol,
  },
}
</script>
