<template>
  <b-form-group
    label="İlgili Proje"
    label-for="id_catalog_statuses"
  >
    <validation-provider
      #default="{ errors }"
      name="İlgili Proje"
      rules="required"
    >
      <v-select
        id="id_projects"
        v-model="dataItem.id_projects"
        :options="dataList"
        label="title"
        :reduce="item => item.id"
        placeholder="Seçiniz"
      >
        <template v-slot:option="option">
          <div class="text-info">
            {{ option.title }}
          </div>
          <div class="font-small-2 text-warning">
            {{ option.customers }}
          </div>
        </template>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'ProjectSelectCard',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  props: {
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['contracts/dataItem']
    },
    dataList() {
      return this.$store.getters['projects/dataList']
    },
  },
  created() {
    this.getData()
    localize('tr')
  },
  methods: {
    getData() {
      this.$store.dispatch('projects/getDataList', {
        select: [
          'projects.id AS id',
          'projects.title AS title',
          'customers.company AS customers',
        ],
        where: {
          'projects.id_project_statuses >': 1,
        },
      })
    },
  },
}
</script>
