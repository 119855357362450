<template>
  <div>
    <b-form-group
      label="Proje Teslim Tarihi"
      label-for="pdate"
    >
      <b-form-datepicker
        id="pdate"
        v-model="dataItem.pdate"
        v-bind="{labelNoDateSelected: 'Başlangıç',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
        locale="tr"
        start-weekday="1"
      />
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormGroup, BFormDatepicker,
} from 'bootstrap-vue'

export default {
  name: 'PDate',
  components: {
    BFormDatepicker,
    BFormGroup,
  },
  computed: {
    dataItem() {
      return this.$store.getters['contracts/dataItem']
    },
  },
}
</script>
