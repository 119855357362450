<template>
  <b-form-group
    label="Sözleşme Şablonu"
    label-for="id_contract_templates"
  >
    <validation-provider
      #default="{ errors }"
      name="Sözleşme Şablonu"
      rules="required"
    >
      <v-select
        id="id_contract_templates"
        v-model="dataItem.id_contract_templates"
        :options="dataList"
        label="title"
        :reduce="item => item.id"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>

    </validation-provider>
  </b-form-group>
</template>

<script>
import { localize, ValidationProvider } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'ContractTemplate',
  components: {
    ValidationProvider,
    BFormGroup,
    vSelect,
  },
  props: {
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      id_contract_templates: null,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['contracts/dataItem']
    },
    dataList() {
      return this.$store.getters['contractTemplates/dataList']
    },
  },
  watch: {
    dataItem: {
      deep: true,
      handler(val) {
        if (this.id_contract_templates !== val.id_contract_templates) {
          this.id_contract_templates = val.id_contract_templates
          this.getTemplate(val.id_contract_templates)
        }
      },
    },
  },
  created() {
    this.getDataList()
    localize('tr')
  },
  methods: {
    getDataList() {
      this.$store.dispatch('contractTemplates/getDataList', {
        select: [
          'contract_templates.id AS id',
          'contract_templates.title AS title',
        ],
      })
    },
    getTemplate(index) {
      this.$store.dispatch('contractTemplates/getDataItem', index)
        .then(response => {
          if (response) {
            this.dataItem.contents = response.content
          }
        })
    },
  },
}
</script>
