<template>
  <div>
    <b-card title="Sözleşme Güncelle">
      <validation-observer ref="simpleRules">
        <contrat-form />
      </validation-observer>
    </b-card>
    <div class="text-left">
      <b-button
        variant="primary"
        class="text-left"
        :disabled="dataItem.submitStatus"
        @click="submitData"
      >
        Kaydet
      </b-button>
    </div>
  </div>
</template>
<script>
import { BCard, BButton } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ContratForm from '@/views/Admin/Contracts/Components/ContractForm.vue'

export default {
  name: 'EditContract',
  components: {
    ContratForm,
    BCard,
    BButton,
    ValidationObserver,
  },
  computed: {
    dataItem() {
      return this.$store.getters['contracts/dataItem']
    },
    saveData() {
      return this.$store.getters['contracts/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.dataItem.submitStatus = false
        this.$router.push({ name: 'Contracts' })
        this.dataItem.submitStatus = false
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('contracts/getDataItem', this.$route.params.id)
    },
    submitData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.dataItem.submitStatus = true
          this.$store.dispatch('contracts/saveData', this.dataItem)
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
